import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import SocialSideBar from './SocialSideBar';
import uniLogo from '../images/uniLogo.png'; // Update the path to your logo1 image
import "./App.css";

export default function App() {

  const handlePhoneClick = (phoneNumber) => {
    if (navigator.userAgent.match(/Android/i)) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Please open this page on a mobile device to make a phone call.");
    }
  };

  const handleEmailClick = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => alert(`Email ${email} copied to clipboard`))
      .catch((error) => console.error('Error copying text: ', error));
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
      window.location.href = `mailto:${email}`;
    } else {
      alert("Please open this page on a mobile device to compose an email.");
    }
  };

  return (
    <MDBFooter
      style={{
        backgroundColor: 'rgba(46, 46, 46, 0.5)',
        backdropFilter: 'blur(10px)',
        color: 'white',
        fontSize: '18px',
      }}
      className='text-center text-lg-start text-muted'
    >
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block' style={{ color: 'white', fontSize: '18px' }}>
          <span>Get connected with us on social networks:</span>
        </div>

        <div className='social'>
          <SocialSideBar />
        </div>

      </section>

      <section>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='6' className='mx-auto mb-4'>
              <p style={{ color: 'white', fontSize: '18px', marginBottom: '0' }}>
                Industrial Management Science Students’ Association<br />
                Department of Industrial Management,<br />
                University of Kelaniya, Sri Lanka
              </p>
              <div className="image-container" style={{ marginTop: '5px', display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <img src="MIT-min.webp" alt="Logo 1" style={{ height: '110px' }} />
                  <img src="IMSSA-min.webp" alt="Logo 2" style={{ height: '55px' ,marginLeft:"-20px"}} />
                  <img src="uok-min.webp" alt="Logo 3" style={{ height: '55px' }} />
              </div>

            </MDBCol>

            <MDBCol md='6' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: 'white', fontSize: '18px', marginBottom: '20px' }}>Contact</h6>
              {/* Rest of your contact information */}
              <p style={{ color: 'white', fontSize: '18px' }}>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                <span onClick={() => handleEmailClick('hackx.mit@gmail.com')} style={{ cursor: 'pointer' }}>hackx.mit@gmail.com</span>
              </p>
              <p style={{ color: 'white', fontSize: '18px' }}>
                <MDBIcon color='secondary' icon='phone' className='me-3' />
                <span onClick={() => handlePhoneClick('+94754061973')} style={{ cursor: 'pointer' }}>+94 75 406 1973</span>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: 'white', fontSize: '18px',opacity:"0.5" }}>
        Copyright © hackX 9.0 2024 - All Rights Reserved.
      </div>
    </MDBFooter>
  );
}

import React from "react";
import Timeline from "../components/TimeLine";

const App = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: "center", color: "white" }}>TIMELINE</h1>
      <br></br>
      <br></br>
      <Timeline />
    </div>
  );
};

export default App;

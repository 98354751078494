import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRef } from "react";

import NavBar from "./components/NavBar";
import WhatIsHackXPage from "./pages/whatIsHackX";
import TimeLine from "./pages/TimeLine";
import ProcessDetails from "./pages/Process";
import Team from "./pages/OurTeam";
import Awards from "./pages/Award";
import GalleryDetails from "./pages/Gallery";
import FAQ from "./pages/FAQ";
import Footer from "./components/Footer";
import ParticlesComponent from "./components/ParticlesComponent";
import Timeline1 from "./components/Timeline1/Timeline1";
function App() {

  const homeRef = useRef(null);
  const timelineRef = useRef(null);
  const processRef = useRef(null);
  const awardRef = useRef(null);
  const galleryRef = useRef(null);
  const faqsRef = useRef(null);
  const contactRef = useRef(null);
  const aboutRef = useRef(null);



  return (
    <div className="App">
    <ParticlesComponent />
      <BrowserRouter>
        <NavBar
          homeRef={homeRef}
          aboutRef={aboutRef}
          timelineRef={timelineRef}
          processRef={processRef}
          awardRef={awardRef}
          galleryRef={galleryRef}
          contactRef={contactRef}
          faqsRef={faqsRef}
        />

        <section>
          <Routes>
            <Route path="/" element={<WhatIsHackXPage pageRef={homeRef} />} />
          </Routes>
        </section>
        <br></br>
        <section>
          <Routes>
            <Route path="/" element={<Timeline1 pageRef={timelineRef} />} />
          </Routes>
        </section>
        <br></br>
        <section>
          <Routes>
            <Route path="/" element={<ProcessDetails pageRef={processRef} />} />
          </Routes>
        </section>
        <br></br>
        <section>
          <Routes>
            <Route path="/" element={<Team pageRef={contactRef} />} />
          </Routes>
        </section>
        <br></br>
        <section>
          <Routes>
            <Route path="/" element={<Awards pageRef={awardRef} />} />
          </Routes>
        </section>
        <br></br>
        <section>
          <Routes>
            <Route path="/" element={<GalleryDetails pageRef={galleryRef} />} />
          </Routes>
        </section>
        <br></br>
        <section>
          <Routes>
            <Route path="/" element={<FAQ pageRef={faqsRef} />} />
          </Routes>
        </section>
        <br></br>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
